import * as React from "react";
import { Formik, Form, FormikActions } from "formik";
import FormButton from "./FormButton";
import Modal from "./Modal";
import {
	removeWhiteSpaces,
	createGenericValidationSchema,
	convertValuesToQstring,
} from "./FunctionLib";
import FormCountUp from "./FormCountUp";
import TrackVisibility from "react-on-screen";
import {
	IStandardFormProps,
	FormConstants,
	StandardFormFields,
	WaFormConstants,
} from "./Constants";
import FormFields, { IFieldProps } from "./FormFields";
import ErrorMessage from "./ErrorMessage";
import { GenericFormModel } from "../../ml_modules/websiteApi/GenericFormModel";
import { sanitize } from "dompurify";
import { isNullOrWhiteSpace } from "../../../common/helper/helper";
import "../Style/StandardForm.scss";
import "../Style/CustomField.scss";
import { CustomFieldType } from "./FormField";
import * as moment from "moment";
import { stepOneCompanyFields } from "../../DonateForm/Components/Step1";
import { ENTITY_TYPE } from "../../hooks/auto-complete";

const isBlank = (str: string) => {
	return !str || /^\s*$/.test(str);
};

export function separateCustomFormFields(
	values: any,
	standardFields?: string[],
	formFields?: IFieldProps[],
	isWaForm?: boolean
) {
	const customFields: any = {};
	const fixedValues: any = { ListOfExtraFields: {} };

	const fields = standardFields || StandardFormFields;
	Object.entries(values).forEach(([key, value]) => {
		if (
			(isWaForm &&
				values[WaFormConstants.entityType] === ENTITY_TYPE.Company &&
				StandardFormFields.includes(key)) ||
			(isWaForm &&
				values[WaFormConstants.entityType] !== ENTITY_TYPE.Company &&
				stepOneCompanyFields.includes(key)) ||
			key === WaFormConstants.entityType
		) {
			return;
		}

		if (fields.indexOf(key) < 0) {
			if (formFields && formFields.some((field) => field.Name === key)) {
				if (
					formFields.find((field) => field.Name === key).CustomFieldType ==
					CustomFieldType.DatePicker
				) {
					customFields[key] = moment(value).format("DD/MM/YYYY");
				} else if (
					formFields.find((field) => field.Name === key).CustomFieldType ==
					CustomFieldType.TimePicker
				) {
					customFields[key] = moment(value).format("hh:mm a");
				} else {
					customFields[key] = value;
				}
			}
			customFields[key] = sanitize(String(customFields[key]).trim(), {
				ALLOWED_TAGS: [],
			});
		} else {
			if (
				isWaForm &&
				values[WaFormConstants.entityType] === ENTITY_TYPE.Company &&
				stepOneCompanyFields.includes(key)
			) {
				fixedValues["ListOfExtraFields"][key] =
					typeof values[key] === "string"
						? sanitize(values[key].trim(), { ALLOWED_TAGS: [] })
						: values[key];
				//so we do not mess with the email autorespond
				if (key === WaFormConstants.contactEmail) {
					fixedValues[FormConstants.email] =
						typeof values[key] === "string"
							? sanitize(values[key].trim(), { ALLOWED_TAGS: [] })
							: values[key];
				}
			} else if (key === FormConstants.declaration) {
				fixedValues["ListOfExtraFields"][key] =
					typeof values[key] === "string"
						? sanitize(values[key].trim(), { ALLOWED_TAGS: [] })
						: values[key];
			} else {
				fixedValues[key] = value;
				fixedValues[key] = sanitize(String(fixedValues[key]).trim(), {
					ALLOWED_TAGS: [],
				});
			}
		}
	});

	fixedValues[FormConstants.listOfExtraFields] = {
		...fixedValues[FormConstants.listOfExtraFields],
		...customFields,
	};

	return fixedValues;
}

export default (props: IStandardFormProps) => {
	let getCount = (contentId: string) => {
		fetch(`/Umbraco/Api/GenericForm/GetSubCount?contentId=${contentId}`, { method: "GET" })
			.then((resp) => resp.json())
			.then((data) => {
				setCounter(data);
			});
	};

	React.useEffect(() => {
		if (props.counterEnabled) {
			getCount(props.contentId);
		}
	}, [props.counterEnabled, props.contentId]);
	const [formHasErrors, setFormHasErrors] = React.useState<boolean>(false);
	const [formIsTouched, setFormIsTouched] = React.useState<boolean>(false);

	const [hasSubmit, setHasSubmit] = React.useState<boolean>(false);
	let submitFunction = (
		values: GenericFormModel,
		actions: FormikActions<GenericFormModel>,
		fields: IFieldProps[]
	) => {
		if (!isNullOrWhiteSpace(props.redirectUrl) && props.addValuesToQstring) {
			const finalRedirectUrl = props.redirectUrl + convertValuesToQstring(values);
			if (props.redirectTarget == "_blank") {
				window.open(finalRedirectUrl, props.redirectTarget);
			} else {
				window.location.href = finalRedirectUrl;
			}
		} else {
			const fixedValues = separateCustomFormFields(values, null, fields);
			const ContentId = parseInt(props.contentId, 10);
			const body = { ContentId: ContentId, ...fixedValues };
			const resp = fetch("/Umbraco/Api/GenericForm/Submit", {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(body),
			}).then((resp) => {
				if (!resp.ok) {
					setSubmitError(resp.statusText);
					actions.setSubmitting(false);
				} else {
					setSubtmitDisabled(true);
					if (props.counterEnabled) {
						getCount(props.contentId);
					}
					actions.resetForm();
					if (!isNullOrWhiteSpace(props.redirectUrl) && props.modalEnabled === false) {
						if (props.redirectTarget == "_blank") {
							window.open(props.redirectUrl, props.redirectTarget);
						} else {
							window.location.href = props.redirectUrl;
						}
					} else {
						if (props.modalEnabled) {
							setShowModal(true);
						}
						setSubmitError("Your form has been successfully submitted. Thank you.");
					}
					if (props.landingPage !== undefined && props.landingPage)
						props.hideLandingPage();
				}
			});
		}
	};

	const [submitError, setSubmitError] = React.useState("");
	const [showModal, setShowModal] = React.useState(false);
	const [submitDisabled, setSubtmitDisabled] = React.useState(false);
	const [counter, setCounter] = React.useState(0);
	const initialValues: any = {
		[FormConstants.firstName]: "",
		[FormConstants.lastName]: "",
		[FormConstants.email]: "",
		[FormConstants.postcode]: "",
	};
	Object.entries(props.fields).forEach(([key, value]) => {
		if (value.CustomFieldType == CustomFieldType.Checkbox) {
			initialValues[removeWhiteSpaces(value.Name)] = false;
		} else if (value.CustomFieldType == CustomFieldType.CheckboxGroup) {
			initialValues[removeWhiteSpaces(value.Name)] = [];
		} else {
			initialValues[removeWhiteSpaces(value.Name)] = "";
		}
	});
	if (props.commonFieldsMeta.State.Required) {
		initialValues[FormConstants.state] = "";
	}
	let formClassName = "genForm " + props.colorTheme;
	if (props.colorTheme === "transparantGrey") {
		formClassName += " transparantGreyBoxShadow";
	}
	if (!props.formImageUrl) {
		formClassName += " genNoImage";
	}
	if (props.narrowForm) {
		formClassName += " narrowForm";
	}

	const getStyle = () => {
		let style = {} as any;
		if (props.landingPage !== undefined && props.landingPage) {
			style.background = "transparent";
			style.boxShadow = "none";
		}
		if (props.narrowForm && !props.mobile) {
			style.width = "400px";
			style.maxWidth = "100%";
		}
		return style;
	};
	const validationSchema = props.isInternaltionalPostCode
		? createGenericValidationSchema({
				commonFieldsMeta: props.commonFieldsMeta,
				fields: props.fields,
				noRegexFor: ["postcode"],
		  })
		: createGenericValidationSchema({
				commonFieldsMeta: props.commonFieldsMeta,
				fields: props.fields,
		  });

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(vals, actions) => submitFunction(vals, actions, props.fields)}
			render={({ errors, touched, values, setFieldValue, isSubmitting }) => {
				if ((Object.keys(errors).length !== 0) !== formHasErrors)
					setFormHasErrors(Object.keys(errors).length !== 0);
				if ((Object.keys(touched).length !== 0) !== formIsTouched)
					setFormIsTouched(Object.keys(touched).length !== 0);
				return (
					<Form>
						<Modal
							showModal={showModal}
							setShowModal={setShowModal}
							shareUrl={props.shareUrl}
							shareTitle={props.shareTitle}
							shareMessage={props.shareMessage}
							shareHashTag={props.shareHashTag}
							modalTitle={props.modalTitle}
							modalMessage={props.modalMessage}
							colorTheme={props.colorTheme}
							modalImageUrl={props.modalImageUrl}
						/>
						<div className={formClassName} style={getStyle()}>
							<div className="contentWrapper">
								<div
									className={
										props.colorTheme === "transparantGrey"
											? "genInnerForm " + props.colorTheme
											: `${
													props.disableGradient ? "" : "genGradient"
											  } genInnerForm` + props.colorTheme
									}
									style={
										props.landingPage
											? { background: "transparent" }
											: undefined
									}
								>
									<div className="container">
										{!isBlank(props.formHeader) && (
											<h1
												className={
													"genFormHeader genOpposite" + props.colorTheme
												}
											>
												{props.formHeader}
											</h1>
										)}
										{!isBlank(props.headerSubtext) && (
											<p
												className={
													"genHeaderSubtext genOpposite" +
													props.colorTheme
												}
											>
												{props.headerSubtext}
											</p>
										)}
										{(!isBlank(props.formHeader) ||
											!isBlank(props.headerSubtext)) && <hr />}
										<FormFields
											errors={errors}
											values={values}
											touched={touched}
											setFieldValue={setFieldValue}
											colorTheme={props.colorTheme}
											fields={props.fields}
											isColumn={props.fieldsAsColumn || props.narrowForm}
											commonFieldsMeta={props.commonFieldsMeta}
											maxCommentLength={props.maxCommentLength}
										/>
									</div>
									{hasSubmit && !submitDisabled && (
										<div
											className={
												props.colorTheme === "transparent"
													? "genErorrMessagesTransparant"
													: "genErrorMessages"
											}
										>
											{Object.keys(values).map((key, index) =>
												touched[key] && errors[key] ? (
													<ErrorMessage
														errors={errors}
														touched={touched}
														colorTheme={props.colorTheme}
														name={key}
														key={index}
													/>
												) : null
											)}
										</div>
									)}
									<div>
										<FormButton
											colorTheme={props.colorTheme}
											errors={errors}
											touched={touched}
											buttonText={props.buttonText}
											isEventForm={false}
											setHasSubmit={setHasSubmit}
											submitDisabled={isSubmitting ? true : submitDisabled}
											buttonRoundedCorners={props.buttonRoundedCorners}
											buttonColorTheme={props.buttonColorTheme}
										/>
										{props.counterEnabled && (
											<TrackVisibility>
												<FormCountUp
													description={props.counterDescription}
													countUpToNum={counter}
													colorTheme={props.colorTheme}
													counterStartNum={props.counterStartNum}
												/>
											</TrackVisibility>
										)}
										{submitError && (
											<div className="genSubmitErrorMessage">
												{submitError}
											</div>
										)}
									</div>
								</div>
								{props.formImageUrl && !props.narrowForm && (
									<div className="genImageWrapper">
										<img
											className="genImage img-fluid p-0"
											src={props.formImageUrl}
										></img>
									</div>
								)}
							</div>
						</div>
					</Form>
				);
			}}
		/>
	);
};
