import { paymentInterval } from "../../DonateForm/Components/Step2";
import { ReactStripeElements } from "react-stripe-elements";
import { IFieldProps } from "./FormFields";

export interface IFormValidation {
	isInternaltionalPostCode: boolean;
}

export interface IStandardFormProps extends IRedirect, IFormValidation {
	buttonText: string;
	contentId: string;
	fields: IFieldProps[];
	shareUrl: string;
	shareTitle: string;
	shareMessage: string;
	shareHashTag: string;
	modalEnabled: boolean;
	modalTitle: string;
	modalMessage: string;
	modalImageUrl: string;
	colorTheme: string;
	autocomplete: boolean;
	stripe?: ReactStripeElements.StripeProps;
	eventId?: number;
	paymentRequired?: boolean;
	publishableKey?: string;
	ticketCost?: number;
	maximumTicketsPerReg: number;
	ticketsLeft: number;
	totalTickets: number;
	formImageUrl?: string;
	counterEnabled?: boolean;
	counterDescription?: string;
	counterStartNum?: number;
	formHeader?: string;
	headerSubtext?: string;
	fieldsAsColumn?: boolean;
	narrowForm?: boolean;
	mobile?: boolean;
	landingPage?: boolean;
	paymentProvider?: string;
	hideLandingPage?: () => void;
	buttonRoundedCorners: boolean;
	buttonColorTheme?: string;
	commonFieldsMeta: ICommonFieldsMeta;
	disableGradient?: boolean;
	maxCommentLength?: number;
}
type commonFields = "FirstName" | "LastName" | "Email" | "Postcode" | "State";

export type ICommonFieldsMeta = {
	[X in commonFields]: IFieldMeta;
};

export interface IFieldMeta {
	Show: boolean;
	Required: boolean;
}

export interface IDonateFormProps extends IRedirect, IFormValidation {
	donationDeclaration: string;
	formHeader: string;
	formHeaderSubtext: string;
	donateAmounts: number[];
	tYMessage: string;
	shareUrl: string;
	shareTitle: string;
	shareMessage: string;
	shareHashtag: string;
	contentId: string;
	stripe?: ReactStripeElements.StripeProps;
	paymentProvider: string;
	colorTheme: string;
	formImageUrl: string;
	fieldsAsColumn?: boolean;
	narrowForm?: boolean;
	donationRecipients?: string[];
	landingPage?: boolean;
	hideLandingPage?: () => void;
	rootNode: string;
	buttonRoundedCorners: boolean;
	defaultDonateDes?: string;
	reCaptchaSiteKey: string;
	waEnhancedMode: boolean;
	singlePageForm: boolean;
	initialInterval?: string;
	intervalOptions?: paymentInterval[];
	defaultCurrency: string;
	hideOtherAmount: boolean;
	enableCountryField: boolean;
	formContentId: number;
	customFields: IFieldProps[];
	disableGradient?: boolean;
	recurringDonationOptionsOnFirstPanel?: boolean;
	findDonorByEmailaddress?: boolean;
	paypalClientId?: string;
}

interface IRedirect {
	redirectUrl: string;
	redirectTarget: string;
	addValuesToQstring?: boolean;
}

export interface FormContactDetailsCheck {
	EntityType: string;
	Address: boolean;
	FirstName: boolean;
	LastName: boolean;
	Phone: boolean;
	Postcode: boolean;
	State: boolean;
	Suburb: boolean;
	Mobile: boolean;
	CompanyName: boolean;
	ABN: boolean;
	CompanyAddress: boolean;
	CompanySuburb: boolean;
	CompanyState: boolean;
	CompanyPostCode: boolean;
	ContactFirstName: boolean;
	ContactLastName: boolean;
	ContactEmail: boolean;
	ContactPhone: boolean;
}

export const REPLACE = "to_replace";

export const FormConstants = {
	amount: "Amount",
	firstName: "FirstName",
	lastName: "LastName",
	address: "Address",
	suburb: "Suburb",
	postcode: "Postcode",
	country: "Country",
	phone: "Phone",
	email: "Email",
	comments: "Comments",
	state: "State",
	numberOfTickets: "NumberOfTickets",
	listOfExtraFields: "ListOfExtraFields",
	interval: "Interval",
	mobile: "Mobile",
	donationRecipient: "DonationRecipient",
	expiryMonth: "CardExpireMonth",
	expiryYear: "CardExpireYear",
	contentId: "ContentId",
	paypalTransactionId: "PaypalTransactionId",
	paypalSubscriptionId: "PaypalSubscriptionId",
	donationDeclaration: "DonationDeclaration",
	paymentProvider: "PaymentProvider",
	declaration: "Declaration",
};

export const WaFormConstants = {
	campaign: "Campaign",
	account: "Account",
	entityType: "EntityType",
	companyName: "CompanyName",
	abn: "ABN",
	companyAddress: "CompanyAddress",
	companySuburb: "CompanySuburb",
	companyState: "CompanyState",
	companyPostcode: "CompanyPostCode",
	contactFirstName: "ContactFirstName",
	contactLastName: "ContactLastName",
	contactEmail: "ContactEmail",
	contactPhone: "ContactPhone",
};
export const StandardFormFields = [
	FormConstants.firstName,
	FormConstants.lastName,
	FormConstants.email,
	FormConstants.postcode,
	FormConstants.phone,
	FormConstants.mobile,
	FormConstants.address,
	FormConstants.comments,
	FormConstants.state,
	FormConstants.suburb,
];
export const EventFormFields = [
	FormConstants.numberOfTickets,
	FormConstants.contentId,
	FormConstants.amount,
	FormConstants.listOfExtraFields,
	FormConstants.paypalTransactionId,
	FormConstants.paymentProvider,
	FormConstants.declaration,
];

export const States = [
	{
		value: "",
		text: "Select State",
	},
	{
		value: "NSW",
		text: "New South Wales",
	},
	{
		value: "VIC",
		text: "Victoria",
	},
	{
		value: "QLD",
		text: "Queensland",
	},
	{
		value: "SA",
		text: "South Australia",
	},
	{
		value: "WA",
		text: "Western Australia",
	},
	{
		value: "TAS",
		text: "Tasmania",
	},
	{
		value: "NT",
		text: "Northern Territory",
	},
	{
		value: "ACT",
		text: "Australian Capital Territory",
	},
];
