import { useEffect, useState } from "react";
export interface QueryHookProps<T> {
	triggers?: string[];
	skip?: boolean;
	onFetchComplete?: (data: T) => void;
}

export interface QueryHookTemplateProps<T> extends QueryHookProps<T> {
	url: string;
}
export function useQuery<T>(input: QueryHookTemplateProps<T>): {
	loading: boolean;
	refetch: () => Promise<T>;
	data?: T;
	error?: string;
} {
	const { skip, url, triggers, onFetchComplete } = input;
	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<T>(undefined);

	const fetchData = async () => {
		if (!skip) {
			try {
				setLoading(true);
				setError("");
				const res = await fetch(url, {
					method: "GET",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				});
				if (res.ok) {
					const response = await res.json();
					setData(response);
					setLoading(false);
					onFetchComplete?.(response);
					return response;
				} else {
					const err = await res.text();
					setLoading(false);
					setError(err);
				}
			} catch (e) {
				setLoading(false);
				setError("Error: Something went wrong with the query");
			}
		}
	};

	useEffect(
		() => {
			fetchData();
			return () => {
				setError("");
				setLoading(false);
				setData(undefined);
			};
		},
		triggers?.length ? [...triggers?.map((t) => input[t])] : []
	);

	const refetch = () => fetchData();

	return { error, data, loading, refetch };
}
