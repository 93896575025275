import { Field, FormikErrors, FormikTouched } from "formik";
import * as React from "react";
import LoadingOverlay from "react-loading-overlay";
import { ClipLoader } from "react-spinners";
import { unCamelCase } from "../../../common/helper/helper";
import { CountryDropdown } from "../../CountryDropDown";
import {
	FormConstants,
	FormContactDetailsCheck,
	States,
	WaFormConstants,
} from "../../GenericForm/Components/Constants";
import { IFieldProps } from "../../GenericForm/Components/FormFields";
import { AUTOCOMPLETE_FORM_TYPE, ENTITY_TYPE, useAutoComplete } from "../../hooks/auto-complete";
import FormField, { CustomFormFieldFactory } from "./FormField";

interface IStep1Props {
	animClass?: string;
	errors: FormikErrors<unknown>;
	touched: FormikTouched<unknown>;
	values: any;
	setFieldValue: (field: string, value: any) => void;
	colorTheme: string;
	isColumn: boolean;
	enableCountryField: boolean;
	customFields: IFieldProps[];
	findDonorByEmailaddress?: boolean;
	setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
	isWaForm: boolean;
}

export const stepOneAllFields = [
	FormConstants.firstName,
	FormConstants.lastName,
	FormConstants.address,
	FormConstants.suburb,
	FormConstants.state,
	FormConstants.postcode,
	FormConstants.country,
	FormConstants.phone,
	FormConstants.email,
];

export const stepOneCompanyFields = [
	WaFormConstants.companyName,
	WaFormConstants.abn,
	WaFormConstants.companyAddress,
	WaFormConstants.companySuburb,
	WaFormConstants.companyState,
	WaFormConstants.companyPostcode,
	WaFormConstants.contactFirstName,
	WaFormConstants.contactLastName,
	WaFormConstants.contactEmail,
	WaFormConstants.contactPhone,
];

export const stepOneStandardFields = [
	FormConstants.firstName,
	FormConstants.lastName,
	FormConstants.address,
	FormConstants.suburb,
	FormConstants.state,
	FormConstants.postcode,
	FormConstants.phone,
	FormConstants.email,
];

export const StepOneFieldFactory: React.FC<IStep1Props & { fieldName: string }> = (props) => {
	switch (props.fieldName) {
		default:
			return (
				<FormField
					className="form-control"
					type="text"
					name={props.fieldName}
					placeholder={unCamelCase(props.fieldName)}
					errors={props.errors}
					touched={props.touched}
					colorTheme={props.colorTheme}
					isColumn={props.isColumn}
				/>
			);

		case FormConstants.state: {
			const stateList = props.enableCountryField
				? [...States, { value: "Other", text: "Other" }]
				: States;
			return (
				<FormField
					colorTheme={props.colorTheme}
					isColumn={props.isColumn}
					type="text"
					className="form-control standard-form-group"
					name={FormConstants.state}
					component="select"
					placeholder="State*"
					errors={props.errors}
					touched={props.touched}
					children={stateList.map((opt, index) => (
						<option key={index} value={opt.value}>
							{opt.text}
						</option>
					))}
				/>
			);
		}

		case WaFormConstants.companyState: {
			const stateList = props.enableCountryField
				? [...States, { value: "Other", text: "Other" }]
				: States;
			return (
				<FormField
					colorTheme={props.colorTheme}
					isColumn={props.isColumn}
					type="text"
					className="form-control standard-form-group"
					name={WaFormConstants.companyState}
					component="select"
					placeholder="State*"
					errors={props.errors}
					touched={props.touched}
					children={stateList.map((opt, index) => (
						<option key={index} value={opt.value}>
							{opt.text}
						</option>
					))}
				/>
			);
		}

		case FormConstants.country:
			return props.enableCountryField ? (
				<CountryDropdown
					name={FormConstants.country}
					hasError={props.errors[FormConstants.country]}
					isTouched={props.touched[FormConstants.country]}
					isColumn={props.isColumn}
				/>
			) : null;
	}
};
const DonateFormStepOne: React.FC<IStep1Props> = (props: IStep1Props) => {
	const isWaCompany =
		props.isWaForm && props.values[WaFormConstants.entityType] === ENTITY_TYPE.Company;
	const {
		loading: isFetchingDonorData,
		autocompletedFields,
		setAutocompletedFields,
	} = useAutoComplete({
		emailAddress: props.values[FormConstants.email],
		entityType: isWaCompany ? ENTITY_TYPE.Company : ENTITY_TYPE.Individual,
		autocompleteType: AUTOCOMPLETE_FORM_TYPE.DonateForm,
		contactEmailAddress: props.values[WaFormConstants.contactEmail],
		errors: props.errors,
		setFieldTouched: props.setFieldTouched,
		setFieldValue: props.setFieldValue,
		skip: !props.findDonorByEmailaddress,
	});
	const autoCompleted = autocompletedFields
		? Object.values(autocompletedFields).some((value) => value === true)
		: false;
	return (
		<div className={`donStep1 container`}>
			{props.isWaForm ? (
				<div className="row" style={{ justifyContent: "center" }}>
					<Field
						className={"donOpposite" + props.colorTheme}
						component="div"
						style={{ display: "flex" }}
						name={WaFormConstants.entityType}
					>
						<div className="donRadioWrapper form-check">
							<input
								defaultChecked={
									props.values[WaFormConstants.entityType] ===
									ENTITY_TYPE.Individual
								}
								name={WaFormConstants.entityType}
								type="radio"
								value="individual"
								className="form-check-input"
								id="individual"
								style={{ cursor: "pointer" }}
							/>
							<label
								className="form-check-label"
								style={{ cursor: "pointer" }}
								htmlFor="individual"
							>
								I donate as an individual
							</label>
						</div>
						<div className="donRadioWrapper form-check">
							<input
								defaultChecked={
									props.values[WaFormConstants.entityType] === ENTITY_TYPE.Company
								}
								name={WaFormConstants.entityType}
								type="radio"
								value="company"
								className="form-check-input"
								id="company"
								style={{ cursor: "pointer" }}
							/>
							<label
								className="form-check-label"
								style={{ cursor: "pointer" }}
								htmlFor="company"
							>
								I donate on behalf of an association or company
							</label>
						</div>
					</Field>
				</div>
			) : null}
			{props.findDonorByEmailaddress && (
				<div className="row">
					{isWaCompany ? (
						<StepOneFieldFactory
							fieldName={WaFormConstants.contactEmail}
							{...props}
							isColumn={true}
						/>
					) : (
						<StepOneFieldFactory
							fieldName={FormConstants.email}
							{...props}
							isColumn={true}
						/>
					)}
					{autoCompleted && (
						<p
							className={"col-md-12 donTaxMessage donOpposite" + props.colorTheme}
							style={{ marginBottom: 0, marginTop: 10 }}
						>
							Welcome back! If your address or phone number has changed since the last
							time you donated,{" "}
							<label
								style={{ textDecoration: "underline", cursor: "pointer" }}
								onClick={() => {
									setAutocompletedFields(undefined);
									props.setFieldValue(FormConstants.address, "");
									props.setFieldValue(FormConstants.firstName, "");
									props.setFieldValue(FormConstants.lastName, "");
									props.setFieldValue(FormConstants.phone, "");
									props.setFieldValue(FormConstants.postcode, "");
									props.setFieldValue(FormConstants.state, "");
									props.setFieldValue(FormConstants.suburb, "");
									props.setFieldValue(WaFormConstants.companyName, "");
									props.setFieldValue(WaFormConstants.abn, "");
									props.setFieldValue(WaFormConstants.companyAddress, "");
									props.setFieldValue(WaFormConstants.companySuburb, "");
									props.setFieldValue(WaFormConstants.companyState, "");
									props.setFieldValue(WaFormConstants.companyPostcode, "");
									props.setFieldValue(WaFormConstants.contactFirstName, "");
									props.setFieldValue(WaFormConstants.contactLastName, "");
									props.setFieldValue(WaFormConstants.contactPhone, "");
								}}
							>
								click here to update them.
							</label>
						</p>
					)}
				</div>
			)}
			<LoadingOverlay
				active={isFetchingDonorData}
				className={"loadingOverlay"}
				spinner={<ClipLoader sizeUnit={"px"} size={20} color={"white"} />}
			>
				<div className="row">
					{(isWaCompany ? stepOneCompanyFields : stepOneAllFields)
						.filter((field) => {
							if (
								props.findDonorByEmailaddress &&
								(field === FormConstants.email ||
									field === WaFormConstants.contactEmail)
							) {
								return false;
							} else if (autoCompleted) {
								if (autoCompleted[field] === false) {
									return true;
								} else {
									return false;
								}
							} else {
								return true;
							}
						})
						.map((f, i) => (
							<StepOneFieldFactory key={i} fieldName={f} {...props} />
						))}
				</div>
			</LoadingOverlay>
			<div className="row">
				{props?.customFields?.map((f, i) => {
					return CustomFormFieldFactory(
						f,
						props.isColumn,
						i,
						props.errors,
						props.touched,
						props.colorTheme,
						props.setFieldValue
					);
				})}
			</div>
		</div>
	);
};

export default DonateFormStepOne;
