import { FormikErrors } from "formik";
import { useDebounce } from "../../common/debounce";
import {
	FormConstants,
	FormContactDetailsCheck,
	REPLACE,
	WaFormConstants,
} from "../GenericForm/Components/Constants";
import { useQuery } from "./query-template";
import { useEffect, useState } from "react";

export enum AUTOCOMPLETE_FORM_TYPE {
	DonateForm,
	EventForm,
}

export enum ENTITY_TYPE {
	Company = "company",
	Individual = "individual",
}

const AUTOCOMPLETE_URL_MAP: Record<AUTOCOMPLETE_FORM_TYPE, string> = {
	[AUTOCOMPLETE_FORM_TYPE.DonateForm]: "DonateForm/GetDonorDataCheck",
	[AUTOCOMPLETE_FORM_TYPE.EventForm]: "EventRegForm/GetEventDataCheck",
};

const CompanyAutocompleteFields = {
	CompanyName: WaFormConstants.companyName,
	ABN: WaFormConstants.abn,
	CompanyAddress: WaFormConstants.companyAddress,
	CompanySuburb: WaFormConstants.companySuburb,
	CompanyState: WaFormConstants.companyState,
	CompanyPostCode: WaFormConstants.companyPostcode,
	ContactFirstName: WaFormConstants.contactFirstName,
	ContactLastName: WaFormConstants.contactLastName,
	ContactPhone: WaFormConstants.contactPhone,
};

const IndividualAutocompleteFields = {
	FirstName: FormConstants.firstName,
	LastName: FormConstants.lastName,
	Address: FormConstants.address,
	Phone: FormConstants.phone,
	Mobile: FormConstants.mobile,
	State: FormConstants.state,
	Suburb: FormConstants.suburb,
	Postcode: FormConstants.postcode,
};

type AutoCompleteQueryHookProps<T> = {
	emailAddress: string;
	contactEmailAddress: string;
	entityType: string;
	autocompleteType: AUTOCOMPLETE_FORM_TYPE;
	errors: FormikErrors<T>;
	setFieldValue: (field: string, value: unknown) => void;
	setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
	skip?: boolean;
};

export function useAutoComplete<T>(input: AutoCompleteQueryHookProps<T>) {
	const {
		emailAddress,
		entityType,
		autocompleteType,
		contactEmailAddress,
		setFieldTouched,
		setFieldValue,
		skip,
		errors,
	} = input;

	const [autocompletedFields, setAutocompletedFields] =
		useState<FormContactDetailsCheck>(undefined);

	const autocompleteUrl = AUTOCOMPLETE_URL_MAP[autocompleteType];

	const emailAddressToUse =
		entityType === ENTITY_TYPE.Company ? contactEmailAddress : emailAddress;
	const emailError =
		entityType === ENTITY_TYPE.Company
			? errors[WaFormConstants.contactEmail]
			: errors[FormConstants.email];

	const debouncedEmailAddressToUse = useDebounce(emailAddressToUse, 2000);
	const queryHookInput = { emailAddress: debouncedEmailAddressToUse };

	const { loading, error, refetch } = useQuery<FormContactDetailsCheck>({
		triggers: ["emailAddress"],
		skip:
			debouncedEmailAddressToUse === "" ||
			debouncedEmailAddressToUse === undefined ||
			skip ||
			emailError,
		...queryHookInput,
		url: `/Umbraco/Api/${autocompleteUrl}?emailAddress=${debouncedEmailAddressToUse}&entityType=${entityType}`,
		onFetchComplete: (data) => {
			if (data !== null) {
				if (data.EntityType === ENTITY_TYPE.Company) {
					Object.entries(CompanyAutocompleteFields).forEach(
						([completeField, formField]) => {
							if (data[completeField] === true) {
								setFieldValue(formField, REPLACE);
								setFieldTouched(formField, true, true);
							}
						}
					);
				}
				if (data.EntityType === ENTITY_TYPE.Individual) {
					Object.entries(IndividualAutocompleteFields).forEach(
						([completeField, formField]) => {
							if (data[completeField] === true) {
								setFieldValue(formField, REPLACE);
								setFieldTouched(formField, true, true);
							}
						}
					);
				}
				setAutocompletedFields(data);
			}
		},
	});

	useEffect(() => {
		if (autocompletedFields) {
			setFieldValue(FormConstants.address, "");
			setFieldValue(FormConstants.firstName, "");
			setFieldValue(FormConstants.lastName, "");
			setFieldValue(FormConstants.phone, "");
			setFieldValue(FormConstants.postcode, "");
			setFieldValue(FormConstants.state, "");
			setFieldValue(FormConstants.suburb, "");
			setFieldValue(FormConstants.mobile, "");
			setFieldValue(WaFormConstants.companyName, "");
			setFieldValue(WaFormConstants.abn, "");
			setFieldValue(WaFormConstants.companyAddress, "");
			setFieldValue(WaFormConstants.companySuburb, "");
			setFieldValue(WaFormConstants.companyState, "");
			setFieldValue(WaFormConstants.companyPostcode, "");
			setFieldValue(WaFormConstants.contactFirstName, "");
			setFieldValue(WaFormConstants.contactLastName, "");
			setFieldValue(WaFormConstants.contactPhone, "");
			setAutocompletedFields(undefined);
		}
	}, [emailAddressToUse, entityType]);

	return { autocompletedFields, loading, error, refetch, setAutocompletedFields };
}
